import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
	slickDots: {
		width: "70%",
		top: "70%",
		left: "10%",
		bottom: 0,
	},
	slickPrev: {
		top: "50% !important",
		left: "-15px !important",
	},

	slickNext: {
		right: "-20px !important",
		top: "50% !important",
	},

	slickList: {
		marginLeft: "1.1rem",
	},
	slickPos: (props) => ({
		position: "fixed",
		bottom: "5vh",
		left: "340px",
		width: `${props.mapSize - 70}px`,
		height: "70px",
		"& :focus": {
			outline: "none",
		},
		"& h3": {
			backgroundColor: "#e7e7e7",
			color: "#000000",
			fontSize: "16px",
			lineHeight: "100px",
			margin: "0 5px",
			padding: "1%",
			position: "relative",
			height: "65px",
			width: "80%",
			textAlign: "center",
		},
	}),
}));
