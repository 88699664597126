import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	topRightMenu: {
		zIndex: 10,
		width: "3rem",
		position: "fixed",
		top: 0,
		right: ".7rem",
		height: "5rem",
		marginTop: "3rem",
		backgroundColor: "transparent",
		"& ul": {
			paddingLeft: 0,
			marginRight: "1.5rem",
		},
		"& li": {
			listStyleType: "none",
		},
	},
	icon: {
		width: "1.6rem",
		height: "1.6rem",
		margin: ".7rem 0",
		cursor: "pointer",
	},
	iconSearch: {
		width: "1.2rem",
		height: "1.2rem",
		marginLeft: ".3rem",
	},
	btnSearch: {
		backgroundColor: "transparent !important",
		border: "none !important",
		marginBottom: 0,
		boxShadow: "none !important",
		paddingLeft: 0,
		"& :focus & :active": {
			outline: "none !important",
			boxShadow: "none !important",
			transition: "none",
		},
	},
});
