import React, { useRef } from "react";

import CustomCard from "../CustomCard";
import Slider from "react-slick";

import { useHistory } from "react-router-dom";
import { formatPoiUrl } from "../../utils";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

import useStyles from "./style.js";

const SlickCards = (props) => {
	let history = useHistory();
	const classes = useStyles(props);

	const { allPois, onHoveringCard } = props;
	const sliderRef = useRef(null);

	const settings = {
		dots: false,
		dotsClass: classes.slickDots,
		infinite: allPois && allPois.length > 5,
		speed: 500,
		centerMode: false,
		slidesToShow: 5,
		slidesToScroll: 1,
		centerPadding: 0,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1100,
				settings: {
					slidesToShow: 2,
				},
			},
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 1,
				},
			},
		],
	};

	
	return (
		<div className={classes.slickPos}>
			<Slider ref={sliderRef} {...settings}>
				{props.allPois.map((poi, index) => {
						return (
							<div key={index} className={classes.slickList}>
								<h3>
									<CustomCard
										name={poi.properties.name}
										thumbnail={
											poi.properties.thumbnail ? poi.properties.thumbnail : ""
										}
										onClick={() => {
											history.push(
												`/poi_detail/${formatPoiUrl(poi.properties.name)}/${poi.properties.id}`
											);
										}}
										onMouseOver={() => {
											return onHoveringCard(poi.properties.id);
										}}
										onMouseOut={() => {
											return onHoveringCard(null);
										}}
									></CustomCard>
								</h3>
							</div>
						);
					})
				}
				{!props.allPois && <div>Loading...</div>}
			</Slider>
		</div>
	);
};;
export default SlickCards;
