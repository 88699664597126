import React from "react";
import { Container, Row, Col } from "reactstrap";

import Header from "../../components/Header";
import TopRightMenu from "../../components/TopRightMenu";

const OuterLayout = props => {
  return (
    <div>
      <TopRightMenu allPois={props.pois} search={false} />
      <Container>
        <Row className="pb-4">
          <Col>
            <Header />
            {props.children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OuterLayout;
