import React, { Component } from "react";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { withStyles } from "@material-ui/styles";
import styles from "./style.js";

class AuthorSearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: false,
			value: "",
			authorParam: this.props.authorParam ? this.props.authorParam : undefined,
			fromMap: this.props.fromMap,
		};
	}

	render() {
		return (
			<div className={this.props.classes.menuPos}>
				<Autocomplete
					id="search-author"
					options={this.props.authors.map((option) => option)}
					getOptionLabel={(option) => option.name}
					autoComplete
					disableClearable
					onChange={(option, value) => {
						this.setState({ value: value, selected: true });
						this.props.handleAuthorChange(value);
					}}
					style={{ width: 210 }}
					classes={{
						root: this.props.classes.autocomplete,
						input: this.props.classes.input,
						inputRoot: this.props.classes.inputRoot,
						focus: this.props.classes.autocompleteFocus,
						paper: this.props.classes.paper,
						listbox: this.props.classes.listbox,
						popupIndicator: this.props.classes.popupIndicator,
						endAdornment: this.props.classes.endAdornment,
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							fullWidth
							multiline={true}
							rows={2}
							placeholder={
								!this.props.authorParam || this.props.fromMap
									? "Autori"
									: this.props.authorParam
							}
							value={this.props.value}
							className={this.props.classes.textField}
							InputProps={{ ...params.InputProps, disableUnderline: true }}
						/>
					)}
				/>
			</div>
		);
	}
}

export default withStyles(styles)(AuthorSearchMenu);
