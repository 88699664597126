import React, { useState, useEffect } from "react";
import ControlledCarousel from "../Carousel";

import Carousel from "react-images";
import "react-responsive-modal/styles.css";
import "./style.css";
import { Modal } from "react-responsive-modal";
import { reStructureImages } from "../../utils/imageGalleryFunctions.js";

const ImageGallery = (props) => {
  const [images, setImages] = useState([]);
  const [currentImage, setCurrentImage] = useState("");
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  useEffect(() => {
    if (props.images) {
      setImages(reStructureImages(props.images));
    } else {
      setImages([]);
    }
  }, [props.images]); // eslint-disable-line

  const openLightbox = (photo, index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const customStyle = {
		view: () => ({
			display: "block",
			padding: "3rem",
			maxWidth: "55rem",
			maxHeight: "55rem",
			margin: "auto",
		}),
		headerClose: () => ({
			color: "#fff",
			":hover": { color: "#DE350B" },
		}),
		footerCaption: () => ({
			display: "none"
		}),
		footerCount: () => ({
			display: "none"
		}),
	};

  const explode_title = (capt) => {
    let mycaption;
    mycaption = capt.split("/");
    mycaption = mycaption[mycaption.length - 1];
    mycaption = mycaption.split(".");
    mycaption = mycaption[0];
    return mycaption;
  };

  return (
    <div>
      {props.images ? (
        <ControlledCarousel photos={images} onClick={() => openLightbox()} />
      ) : null}
      <Modal
        open={viewerIsOpen}
        onClose={() => closeLightbox()}
        center
        classNames={{
          modal: "customModal",
        }}
      >
        <Carousel
          currentIndex={currentImage}
          views={images.map((x) => ({
            ...x,
            caption: explode_title(x.src),
          }))}
          styles={customStyle}
        />
      </Modal>
    </div>
  );
};

export default ImageGallery;
