import React, { useState, useEffect } from "react";

import { useQuery } from "../../utils/hooks";

import useStyles from "./style.js";

const ItinerariesDropdown = ({ handleChange, folders, itineraryDropdownOpen }) => {
	const query = useQuery();

	const itineraryParam = query.get("itinerary");
	const fromMap = query.get("from_map");
	const [isOpen, setIsOpen] = useState(itineraryParam ? true : false);
	const [visibility, setVisibility] = useState("hidden");
	const [selected, setSelected] = useState(
		itineraryParam ? itineraryParam : ""
	);

	useEffect(() => {
		if (fromMap) {
			setIsOpen(false);
			setSelected("");
			itineraryDropdownOpen(false);
		} else {
			if (itineraryParam) {
				setIsOpen(true);
				itineraryDropdownOpen(true);
			}
			if (!itineraryParam) {
				setIsOpen(false);
				setSelected("");
				itineraryDropdownOpen(false);
			}
		}
	}, [itineraryParam, fromMap]);

	useEffect(() => {
		if (isOpen) setVisibility("visible");
		else setVisibility("hidden");
	}, [isOpen]);

	const styleProps = {
		visibility,
	};

	const classes = useStyles(styleProps);

	return (
		<div className={classes.menuWrapper}>
			<h4
				onClick={() => {
					setIsOpen(!isOpen);
					itineraryDropdownOpen(!isOpen);
				}}
			>
				Itinerari
			</h4>
			<div className={classes.menu}>
				<ul className={classes.list}>
					{folders &&
						folders.map((itineraryName, index) => {
							return (
								<li
									key={index}
									className={classes.listItem}
									onClick={() => {
										handleChange(itineraryName);
										setSelected(itineraryName);
									}}
									style={{
										color: selected === itineraryName ? "#6699CC" : "#212529",
										fontWeight: selected === itineraryName ? "bold" : "normal",
									}}
								>
									{itineraryName}
								</li>
							);
						})}
				</ul>
			</div>
		</div>
	);
};

export default ItinerariesDropdown;
