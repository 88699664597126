import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  footer: {
    marginTop: '2rem',
    marginLeft: '2rem',
    marginBottom: '.5rem',
    position: 'fixed',
    bottom: '0px',
    zIndex: 5
  },
  footerLink: {
    color: theme.darkMode ? 'white' : 'black',
    margin: '1rem',
    marginLeft: 0,
    border: 'none',
    textDecoration: 'underline',
    "&:hover": {
      color: theme.darkMode ? 'white' : 'black',
      border: 'none'
    }
  }
}));
