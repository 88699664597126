import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ThemeProvider } from "@material-ui/core/styles";

import AlertCookie from "../../components/AlertCookie";
// import GSModal from "../../components/GSModal";
import BackgroundVideo from "../../components/BackgroundVideo";
import useStyles from "./style.js";

import whiteMap from "../../data/icons/ic_map_big.svg";
import whiteLogo from "../../data/icons/ic_logo_white.svg";


const Home = () => {
	const [cookieAccepted, setCookieAccepted] = useState("");
	// const password = "museocluster2021";
	// const [psw, setPsw] = useState(sessionStorage.getItem("password") ? true : false);
	let device = "desktop";

	useEffect(
		() => setCookieAccepted(localStorage.getItem("cookieAccepted") === "true"),
		[cookieAccepted]
	);
	useEffect(() => {
		getDeviceType();
		if (device !== "desktop") {
			window.location.href = "http://m.museocagliari.it/";
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [device]);

	const handleCookieClick = () => {
		localStorage.setItem("cookieAccepted", true);
		setCookieAccepted(true);
	};

	/* const handlePsw = (value) => {
		if (value === password) {
			sessionStorage.setItem("password", value);
			setPsw(true);
			getDeviceType()
			if (device !== "desktop") {
				window.location.href = "http://m.museocagliari.it/"; 
			}
		}
	}; */

	const getDeviceType = () => {
		const ua = navigator.userAgent;
		if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
			device = "tablet";
		}
		if (
			/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
				ua
			)
		) {
			device = "mobile";
		}
	};

	const classes = useStyles();
	let numOfSec = Math.floor(Math.random() * 200);
	return (
		<ThemeProvider theme={(outerTheme) => ({ ...outerTheme, darkMode: true })}>
			{/* {!psw && <GSModal handlePsw={handlePsw} />}
			{psw && device === "desktop" && ( */}
			{device === "desktop" && (
				<div>
					<BackgroundVideo
						urlVideo="https://www.youtube.com/embed/S54AJqjfNVI?autoplay=1&controls=1&fs=0&loop=1&playlist=S54AJqjfNVI&modestbranding=1&iv_load_policy=3&enablejsapi=1&widgetid=1&wmode=opaque&mute=1"
						numOfSec={`&start=${numOfSec}`}
					/>
					<div className={classes.wrapContent}>
						<div className={classes.museoTitle}>
							<img src={whiteLogo} alt="Logo" className={classes.imgLogo} />
							<Link to="/about" className={classes.anchor}>
								<h1>MuseoCagliari</h1>
							</Link>
						</div>
						<a href="/map">
							<img src={whiteMap} alt="Map icon" className={classes.imgMap} />
						</a>
						<div className={classes.subHeader}>
							<h4>Percorsi di arte pubblica e architettura moderna</h4>
						</div>
					</div>
					{/* TODO: refactor
						<Footer className={classes.homeFooter} />
					*/}
					<div className={classes.cookieText}>
						{!cookieAccepted && <AlertCookie onClick={handleCookieClick} />}
					</div>
				</div>
			)}
		</ThemeProvider>
	);
};

export default Home;
