import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import 'bootstrap/dist/js/bootstrap.js';

import 'bootstrap/dist/css/bootstrap.min.css';
import "leaflet/dist/leaflet.css";
import "./index.css";

const rootElement = document.querySelector('[data-role-react-root]');

ReactDOM.render(<App />, rootElement);
