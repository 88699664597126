import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pois: [],
  folders: []
};

export const openDataSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setPois: (state, action) => {
      return {
        ...state,
        pois: action.payload,
      };
    },
    setFolders: (state, action) => {
      return {
        ...state,
        folders: action.payload,
      };
    }
  }
});
