import { makeStyles } from '@material-ui/core/styles';

export default makeStyles( {
  popupCard: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    borderRadius: '5px',
    cursor: 'pointer',
    "& h6": {
      fontSize: '.8rem',
      marginBottom: 0
    }
  },
  cardImg: {
    width: '50px',
    height: '40px',
    paddingLeft: '5px'
  }
});
