import React from "react";
import { useHistory } from "react-router";

import URI from "urijs";
import { Row, Col } from "reactstrap";
import ImageGallery from "../ImageGallery";

// import arrowIcon from "../../data/icons/ic_itinerario.png";

import useStyles from "./style.js";

const setParameters = (params) => {
	var uri = new URI("");
	uri.setQuery(params);
	return uri.query();
};

const ContentPoi = (props) => {
	const history = useHistory();
  const biblioItems = props.biblio && props.biblio.split("<br>");
  const itineraries = props.itinerari && props.itinerari.split(" - ");

  const classes = useStyles(props);
  
  return (
		<div className={classes.gradient}>
			<div className={classes.poiDescriptionContainer}>
				<Row>
					<Col style={{ textAlign: "center" }}>
						<ul style={{ paddingLeft: 0, marginBottom: 0 }}>
							{props.itinerari
								? itineraries.map((item, index) => {
										return (
											<li
												key={index}
												style={{cursor: "pointer"}}
												className={classes.listItem}
												onClick={() => {
													history.push({
														pathname: "/map",
														search: setParameters({
															itinerary: item,
															open: "true",
														}),
													});
												}}
											>
												<small>
													{/* <img
														src={arrowIcon}
														style={{ marginRight: "1rem", width: "2rem" }}
														alt="Icona itinerario"
													/> */}
													{item}
												</small>
											</li>
										);
								  })
								: ""}
						</ul>
					</Col>
				</Row>
				<Row style={{ marginTop: "3rem" }}>
					<Col>
						<p>{props.description ? props.description : ""}</p>
						<br />
						<ImageGallery images={props.images} />
						<br />
						<p>{props.description2 ? props.description2 : ""}</p>
						{props.biblio && (
							<p
								style={{
									textTransform: "uppercase",
									fontSize: "1.2rem",
									marginBottom: 0,
								}}
							>
								Bibliografia:
							</p>
						)}
						<ul style={{ paddingLeft: 0 }}>
							{props.biblio &&
								biblioItems.map((item, index) => {
									return (
										<li key={index} className={classes.listItem}>
											{item}
										</li>
									);
								})}
						</ul>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default ContentPoi;
