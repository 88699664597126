import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  alert: {
    backgroundColor: 'rgba(0, 0, 0, .8)',
    borderColor: 'none !important',
    color: 'white',
    marginBottom: 0,
    border: 'none',
    borderRadius: 0
  },
  btnCookie: {
    backgroundColor: 'rgba(0, 0, 0, .8)'
  } 
});
