import React from "react";

const Header = () => {
  return (
    <header className="text-center mb-3">
      <h1 className="mt-4 mb-0">MuseoCagliari</h1>
      <small>
        Piattaforma open-data dell'architettura di qualità
      </small>
    </header>
  );
};

export default Header;
