import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { formatPoiUrl } from "../../utils";

import { withStyles } from "@material-ui/styles";
import styles from "./style.js";

class PoisSearchMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selected: false,
			value: ""
		};
	}
	
	render() {
		return (
			<div className={this.props.classes.menuPos}>
				<Autocomplete
					id="combo-box-search-poi"
					options={this.props.allPois.map((option) => option.properties)}
					getOptionLabel={(option) => option.name}
					autoComplete
					clearOnEscape
					onChange={(option, value) => {
						if (value) {
							this.props.history.push(
								`/poi_detail/${formatPoiUrl(value.name)}/${value.id}`
							);
						}
					}}
					style={{ width: 280 }}
					classes={{
						root: this.props.classes.autocomplete,
						input: this.props.classes.input,
						inputRoot: this.props.classes.inputRoot,
						focus: this.props.classes.autocompleteFocus,
						paper: this.props.classes.paper,
						listbox: this.props.classes.listbox,
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							onChange={(event) => {
								this.setState({ value: event.target.value, selected: true });
							}}
							placeholder={this.props.selected ? "" : "Cerca Pois"}
							value={this.props.value}
							fullWidth
							className={this.props.classes.textField}
							InputProps={{
								...params.InputProps,
								classes: {
									underline: this.props.classes.underline,
								},
							}}
						/>
					)}
				/>
			</div>
		);
	}
}

export default withRouter(withStyles(styles)(PoisSearchMenu));
