import React from "react";
import useStyles from "./style.js";

const Image = (props) => {
  const { image } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.imageContainer}>
      {image && (
        <div>
          <div className={classes.gradient}></div>
          <img src={image} className={classes.imageSwap} alt="" />
        </div>
      )}
    </div>
  );
};

export default Image;
