import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
	menuWrapper: {
		width: "210px",
		"& h4": {
			cursor: "pointer",
			marginLeft: "1.5rem",
			marginBottom: ".3rem",
			color: "white",
			fontSize: "24px",
			"&:hover": {
				color: "#6699CC",
			},
		},
	},
	menu: (props) => ({
		visibility: props.visibility,
	}),
	list: {
		marginLeft: "1.5rem",
		padding: "1rem",
		backgroundColor: "rgba(221, 221, 221, .75)",
		width: 210,
		height: "450px",
		overflowY: "scroll",
	},
	listItem: {
		listStyleType: "none",
		fontSize: "14px",
		whiteSpace: "unset",
		wordBreak: "breakWord",
		paddingBottom: ".5rem",
		cursor: "pointer",
		"&:last-child": {
			paddingBottom: 0,
		},
		"&:hover": {
			color: "#6699CC !important",
			fontWeight: "bold !important",
		},
		"&:focus": {
			color: "#6699CC !important",
			fontWeight: "bold !important",
		},
		"&:active": {
			color: "#6699CC !important",
			fontWeight: "bold !important",
		},
	},
});
