import React from "react";

import useStyles from "./style.js";

const Colophon = (props) => {
  const classes = useStyles(props);

  return (
		<div className="mt-4">
			<h3>Gruppo di lavoro - Università di Cagliari</h3>
			<ul className="pl-1 mt-4">
				<li className={classes.listItem}>
					<span className={classes.title}>RESPONSABILE SCIENTIFICO</span>
					<br />
					<span className="pl-1">Paolo Sanjust</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>RESPONSABILE SARDEGNA RICERCHE </span>
					<br />
					<span className="pl-1">Dario Carbini</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>
						DICAAR, DIPARTIMENTO DI INGEGNERIA CIVILE, AMBIENTALE E ARCHITETTURA
					</span>
					<br />
					<span className="pl-1">Antonello Sanna</span>
					<br />
					<span className="pl-1">Caterina Giannattasio</span>
					<br />
					<span className="pl-1">Marco Cadinu</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>
						DIPARTIMENTO DI MATEMATICA E INFORMATICA
					</span>
					<br />
					<span className="pl-1">Gianni Fenu</span>
					<br />
					<span className="pl-1">Giovanni Puglisi</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>
						DIPARTIMENTO DI STORIA, BENI CULTURALI E TERRITORIO
					</span>
					<br />
					<span className="pl-1">Fabio Pinna</span>
					<br />
					<span className="pl-1">Rita Pamela Ladogana</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>SUPPORTO AMMINISTRATIVO</span>
					<br />
					<span className="pl-1">Mauro Meloni</span>
				</li>
			</ul>
			<h3>Cluster imprese</h3>
			<ul className="pl-1 mt-4">
				<li className={classes.listItem}>
					<span className={classes.title}>TURISMO</span>
					<br />
					<span className="pl-1">Sardinia To Do</span>
					<br />
					<span className="pl-1">Eidesia</span>
					<br />
					<span className="pl-1">Sun and Sand Sardinia</span>
					<br />
					<span className="pl-1">Movidì Tour</span>
					<br />
					<span className="pl-1">Comunità Impresa</span>
					<br />
					<span className="pl-1">Cavour</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>INFORMATICA</span>
					<br />
					<span className="pl-1">FlossLab</span>
					<br />
					<span className="pl-1">Delfis</span>
					<br />
					<span className="pl-1">Geoinfolab</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>COMUNICAZIONE</span>
					<br />
					<span className="pl-1">Prima Idea</span>
					<br />
					<span className="pl-1">Radio X</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>ARCHITETTURA</span>
					<br />
					<span className="pl-1">In/Arch</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>CONSULENTI E COLLABORATORI</span>
					<br />
					<span className="pl-1">Marina Fanari</span>
					<br />
					<span className="pl-1">Maddalena Mameli</span>
					<br />
					<span className="pl-1">Maddalena Pisanu</span>
					<br />
					<span className="pl-1">Maria Serena Pirisino</span>
					<br />
					<span className="pl-1">Michela Buttu</span>
					<br />
					<span className="pl-1">Antonio Giorri</span>
					<br />
					<span className="pl-1">Davide Nurra</span>
					<br />
					<span className="pl-1">Alessandro Mereu</span>
				</li>
			</ul>
			<h3>Crediti foto</h3>
			<ul className="pl-1 mt-4">
				<li className={classes.listItem}>
					<span className={classes.title}>Architettura:</span>
					<br />
					<span className="pl-1">Alessandra Chemollo</span>
					<br />
					<span className="pl-1">Pierluigi Dessì</span>
					<br />
					<span className="pl-1">Stefano Ferrando</span>
					<br />
					<span className="pl-1">Maddalena Mameli</span>
					<br />
					<span className="pl-1">Lucio Ortu</span>
					<br />
					<span className="pl-1">Salvatore Peluso</span>
					<br />
					<span className="pl-1">Beniamino Pillittu</span>
					<br />
					<span className="pl-1">Antonella Sanna</span>
					<br />
					<span className="pl-1">Paolo Sanjust</span>
					<br />
					<span className="pl-1">Francesco Spanedda</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>Archeologia:</span>
					<br />
					<span className="pl-1">Marco Demuru</span>
				</li>
				<li className={classes.listItem}>
					<span className={classes.title}>Arte:</span>
					<br />
					<span className="pl-1">Tonino Casula</span>
					<br />
					<span className="pl-1">Gianni Dettori</span>
					<br />
					<span className="pl-1">Marco Valerio Loi</span>
					<br />
					<span className="pl-1">Fulvio Orsenigo</span>
					<br />
					<span className="pl-1">Archivio AMA - Rosanna Rossi</span>
					<br />
					<span className="pl-1">Ilisso Editore</span>
					<br />
					<span className="pl-1">Musei Civici di Cagliari</span>
					<br />
					<span className="pl-1">Crisa</span>
					<br />
					<span className="pl-1">Tellas</span>
					<br />
					<span className="pl-1">Antonio Pintus</span>
					<br />
					<span className="pl-1">Marina Fanari</span>
				</li>
			</ul>
			<h3>Crediti video</h3>
			<ul className="pl-1 mt-4">
				<li className={classes.listItem}>
					<span className="pl-1">Marco Tanca</span>
					<p className="pl-1">Teravista</p>
				</li>
			</ul>
			<h3>Sito web</h3>
			<ul className="pl-1 mt-4">
				<li className={classes.listItem}>
					<span className="pl-1">GreenShare</span>
				</li>
			</ul>
		</div>
	);
}

export default Colophon;