import React from "react";

import Info from "../../components/Info";
import OuterLayout from "../../components/Layout/OuterLayout";
import Footer from "../../components/Footer";

const LegalNote = () => {
  const firstP = "Lorem ipsum dolor sit amet, consectetur adipiscing elit.";
  const firstMain =
    "Duis pharetra nulla massa, sit amet faucibus est malesuada ut. Donec vitae feugiat dolor. Nullam aliquet, dui ut dictum malesuada, sapien leo euismod metus, non faucibus risus mauris venenatis dolor. Proin bibendum ullamcorper ex, nec mollis nibh accumsan et. In dui arcu, tempus aliquam tempus id, varius vel diam. In consequat tristique libero, a fermentum lacus semper nec. Suspendisse ultrices purus non libero dignissim, id consectetur sapien imperdiet. Donec elementum mi metus, vitae aliquet purus pulvinar a. Donec in enim bibendum, semper justo at, interdum arcu. Maecenas lectus metus, consequat non urna sit amet, consectetur luctus magna. Proin accumsan nulla eu velit finibus, nec viverra leo dignissim.";
  const secondP = "CC BY_NC SA 3.0";
  const secondMain =
    "Sed finibus convallis lorem, vitae facilisis ante fermentum a.Curabitur aliquet quam et felis aliquet, scelerisque maximus nibh congue.Fusce sit amet ultrices urna, id eleifend diam.Morbi in feugiat justo.Fusce laoreet massa sit amet magna volutpat gravida.Aliquam interdum quam nisi, a ultrices dolor posuere eget.Cras euismod quis mi at fringilla.Vestibulum maximus diam eget varius ultrices.Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.Donec commodo imperdiet dictum.Donec cursus lorem eros, quis blandit enim laoreet id.Nam pulvinar leo ac consectetur semper.Sed blandit nec magna eget tincidunt.Morbi condimentum tortor nec erat scelerisque aliquet.Morbi ullamcorper risus quis cursus tristique.";
  
  return (
    <div>
      <OuterLayout>
        <Info
          title="Note legali"
          firstP={firstP}
          firstMain={firstMain}
          secondP={secondP}
          secondMain={secondMain}
        />
      </OuterLayout>
      <Footer />
    </div>
  );
};

export default LegalNote;
