import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  wrapContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center' 
  },
  museoTitle: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    color: '#ffffff',
    alignItems: 'baseline',
    padding: '1rem',
    opacity: 1,
    top: '10%',
    "& h1": {
      opacity: 1,
      marginBottom: 0
    }
  },
  anchor: {
    color: "white",
    "&:hover": {
      color: "white",
      textDecoration: "none"
    }
  },
  subHeader: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    color: '#ffffff',
    alignItems: 'baseline',
    padding: '1rem',
    opacity: 1,
    bottom: '5%',
    "& h4": {
      opacity: 1,
      marginBottom: 0
    }
  },
  imgMap: {
    width: '4rem',
    position: 'absolute',
    bottom: '15%',
    opacity: 1,
  },
  imgLogo: {
    width: '3rem',
    marginRight: '1rem',
  },
  homeFooter: {
    zIndex: 1,
    background: 'rgba(200, 0, 300, 0, 4)',
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  cookieText: {
    zIndex: 1,
    width: '100%',
    position: 'fixed',
    bottom: 0
  }
});
