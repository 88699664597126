import React from "react";
import useStyles from "./style.js";

const Video = (props) => {
  const { video, height } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.videoContainer}>
      {video && (
        <div>
          <div className={classes.gradient}></div>
          <iframe
            height={height}
            src={video}
            frameBorder="0"
            title="videoDetail"
            data-ready="true"
            allow="autoplay; encrypted-media;"
          />
        </div>
      )}
    </div>
  );
};

export default Video;
