import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
  videoBackground: {
    backgroundColor: 'rgba(23, 23, 23, 1)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: '-99',
    "& iframe": {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none'
    }
  },
  videoForeground: {
    "& iframe": {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      "@media (min-aspect-ratio: 16/9)": {
        height: '300%',
        top: '-100%'
      },
      "@media (max-aspect-ratio: 16/9)": {
        width: '300%',
        left: '-100%'
      }
    }    
  }
}));
