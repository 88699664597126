import React from 'react';

import sardiniatodo from "../../data/img/sardiniatodo.jpg";
import movidi from "../../data/img/movidi.jpg";
import eidesia from "../../data/img/eidesia.jpg";

import useStyles from './style.js';

const Adverts = () => {

  const classes = useStyles();
  return (
		<div className={classes.adverts}>
			<ul className={classes.listAdverts}>
				<a
					className={classes.anchor}
					href={"http://www.sardiniatodo.com"}
					rel="noopener noreferrer"
					target="_blank"
				>
					<li className={classes.listItems}>
						<img
							src={sardiniatodo}
							alt="Sardinia To Do"
							title="Sardinia To Do"
							className={classes.advertLogo}
							style={{ height: "2.8rem" }}
						/>
					</li>
				</a>
				<a
					className={classes.anchor}
					href={"http://www.facebook.com/moviditour"}
					rel="noopener noreferrer"
					target="_blank"
				>
					<li className={classes.listItems}>
						<img
							src={movidi}
							alt="Movidì Agency"
							title="Movidì Agency"
							className={classes.advertLogo}
							style={{ height: "2.8rem" }}
						/>
					</li>
				</a>
				<a
					className={classes.anchor}
					href={"https://www.facebook.com/eidesia"}
					rel="noopener noreferrer"
					target="_blank"
				>
					<li className={classes.listItems}>
						<img
							src={eidesia}
							alt="Eidesia"
							title="Eidesia"
							className={classes.advertLogo}
							style={{ height: "2.8rem" }}
						/>
					</li>
				</a>
			</ul>
		</div>
	);
};

export default Adverts;
