import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  gradient: {
    background: 'linear-gradient(to right, rgba(23, 23, 23, 0.56) 0%, transparent 10%, transparent 90%, rgba(23, 23, 23, 0.56) 100%)'
  },
  poiDescriptionContainer: {
    height: 'auto',
    padding: '1rem',
    margin: 'auto',
    width: '600px',
    lineHeight: '32px',
    fontSize: '1.5rem',
    fontFamily: 'times',
    fontWeight: 400
  },
  listItem: {
    fontSize: '1rem',
    listStyleType: 'none',
    marginBottom: 0
  }
});
