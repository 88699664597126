import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

function ControlledCarousel(props) {

  const settings = {
    dots: true,
    dotsClass: "slick-dots",
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className="imageCarousel">
      <Slider {...settings}>
        {props.photos.map((photo, idx) => (
          <img
            key={idx}
            alt={photo.caption}
            src={photo.src}
            onClick={props.onClick}
          />
        ))}
      </Slider>
    </div>
  );
}

export default ControlledCarousel;
