import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
	gradient: {
		position: "absolute",
		width: "100%",
		height: "100%",
		zIndex: 5,
		pointerEvents: "none",
		background:
			"linear-gradient(to right, rgba(23, 23, 23, 0.56) 0%, transparent 15%, transparent 85%, rgba(23, 23, 23, 0.56) 100%)",
	},
	backgroundWrapper: {
		position: "fixed",
		minWidth: "100%",
		minHeight: "100%",
	},
	itineraryDropdown: {
		zIndex: 10,
		position: "fixed",
		top: "80px",
		left: ".8rem",
	},
	authorsDropdown: {
		zIndex: 10,
		position: "fixed",
		top: "130px",
		left: ".8rem",
	},
	iconSmallMap: {
		position: "fixed",
		top: "50%",
		right: "150px",
		paddingLeft: ".35rem",
		color: "white !important",
		boxShadow: "-5px 5px 5px 0px rgba(80,73,73,0.75)",
		zIndex: 20,
	},
	iconLargeMap: {
		position: "fixed",
		top: "50%",
		left: "250px",
		color: "white !important",
		boxShadow: "5px 5px 5px 0px rgba(80,73,73,0.75)",
	},
	mapFooter: {
		position: "fixed",
		marginLeft: 0,
		marginBottom: ".5rem",
		paddingLeft: "1rem",
	},
	map: {
		position: "fixed",
		height: "100vh",
		opacity: 0.9,
	},
	isSmall: {
		left: "0",
		width: "300px",
	},
	isBig: {
		left: "300px",
		right: "120px",
		width: "auto",
	},
});
