import React, { useState, useEffect } from "react";
import { useQuery } from "../../utils/hooks";

import { Link, useHistory } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';
import { Button } from 'reactstrap';

import PoisSearchMenu from "../PoisSearchMenu";
import Adverts from "../Adverts";

import blackLogo from "../../data/icons/ic_logo.svg";
import blackSearch from "../../data/icons/ic_search.svg";
import blackMap from "../../data/icons/ic_map.svg";
import whiteLogo from "../../data/icons/ic_logo_white.svg";
import whiteSearch from "../../data/icons/ic_search_white.svg";
import whiteMap from "../../data/icons/ic_map_big.svg";

import classNames from 'classnames';
import useStyles from './style.js';

const TopRightMenu = ({ light = false, search = true, map = true, ...props }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
	const [showAdverts, setShowAdverts] = useState(false);

	const query = useQuery();
	const itineraryParam = query.get("itinerary");

  const history = useHistory();
	
  const handleClickAway = () => {
    setOpen(false);
    setMenuOpen(false);
  };

  const toggle = () => {
    setOpen(prev => !prev);
    setMenuOpen(!menuOpen)
  };

	useEffect(() => {
		if (!itineraryParam) {
			setShowAdverts(false);
		} else {
			setShowAdverts(true);
		}
	}, [itineraryParam]);


  const classes = useStyles(props);

  return (
		<div className={classes.topRightMenu}>
			<ul>
				<li>
					<Link to="/" id="logoLink" className={classes.icon}>
						<img
							src={light ? whiteLogo : blackLogo}
							alt="Logo"
							className={classes.icon}
						/>
					</Link>
					<UncontrolledTooltip placement="left" target="logoLink">
						Home page
					</UncontrolledTooltip>
				</li>
				{search && (
					<li>
						<Button
							id="searchLink"
							onClick={toggle}
							className={classes.btnSearch}
						>
							<img
								src={light ? whiteSearch : blackSearch}
								alt="Search"
								className={classNames(classes.icon, classes.iconSearch)}
							/>
						</Button>
						{menuOpen && open && (
							<div onMouseLeave={handleClickAway}>
								<PoisSearchMenu allPois={props.allPois} />
							</div>
						)}
						<UncontrolledTooltip placement="left" target="searchLink">
							Search
						</UncontrolledTooltip>
					</li>
				)}
				{map && (
					<li>
						<div
							id="mapLink"
							style={{ cursor: "pointer" }}
							className="icon"
							onClick={() => {
								history.push("/map?open=true&from_map=true");
							}}
						>
							<img
								src={light ? whiteMap : blackMap}
								alt="Map"
								className={classes.icon}
							/>
						</div>
						<UncontrolledTooltip placement="left" target="mapLink">
							Mappa
						</UncontrolledTooltip>
					</li>
				)}
				{showAdverts && <Adverts />}
			</ul>
		</div>
	);
};

export default TopRightMenu;
