import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  init: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    init: (state, action) => {
      // action.payload
      return {
        ...state,
        init: true,
      };
    },
  },
});
