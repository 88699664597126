import React from "react";
import { Row, Col } from "reactstrap";

import OuterLayout from "../../components/Layout/OuterLayout";
import Footer from "../../components/Footer";

const ConditionsOfUse = () => {
	return (
		<div>
			<OuterLayout>
				<div>
					<Row>
						<Col>
							<div>
								<h2
									className="mb-4"
									style={{ fontWeight: "700", marginTop: "2rem" }}
								>
									Condizioni d'uso
								</h2>
								<p style={{ marginTop: "2rem", marginBottom: ".35rem" }}>
									L'Utente riconosce che le informazioni, i dati, le fotografie,
									la grafica, le immagini, i disegni, i video e qualunque altro
									materiale presente su questo Sito sono di proprietà esclusiva
									del Dipartimento di Ingegneria civile, ambientale e
									Architettura dell’Università di Cagliari.
								</p>
								<p style={{ marginBottom: ".35rem" }}>
									La duplicazione, la riproduzione anche parziale, il download,
									il salvataggio, la pubblicazione o diffusione con qualunque
									mezzo e più in generale qualunque atto di disposizione o
									utilizzo di informazioni o di materiale proveniente da questo
									Sito, in tutte le forme, attraverso media e tecnologie
									esistenti o sviluppate in futuro, sono consentiti, salvo
									espressa indicazione contraria; è obbligatorio però comunicare
									a
									<a
										title=""
										href="mailto:museocagliari@unica.it"
										style={{ color: "black", fontStyle: "italic" }}
									>
										{" "}
										MuseoCagliari@unica.it{" "}
									</a>
									l’elenco dei materiali che si intende utilizzare, e l’uso che
									si intende farne, ed è obbligatorio citare la fonte
									(MuseoCagliari.it).
								</p>
								<p style={{ marginBottom: ".35rem" }}>
									MuseoCagliari.it rispetta i diritti di proprietà intellettuale
									altrui e richiede agli utenti del presente Sito di fare lo
									stesso. L'Utente può usare il Sito unicamente per attività
									legittime e non dovrà utilizzare il Sito per alcuna attività
									fraudolenta.
								</p>
								<p style={{ marginBottom: ".35rem" }}>
									Le immagini e i testi disponibili sul Sito possono essere
									scaricate e stampate per uso personale e possono essere
									riprodotti e utilizzati, in formato elettronico.
								</p>
								<p style={{ marginBottom: ".35rem" }}>
									Gli Utenti che dovessero compiere atti fraudolenti o illegali
									saranno perseguiti a norma di legge.
								</p>
							</div>
						</Col>
					</Row>
				</div>
			</OuterLayout>
			<Footer />
		</div>
	);
};

export default ConditionsOfUse;
