import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	listItem: {
		display: "inlineBlock",
		listStyleType: "none",
    marginBottom: '1rem'
	},
	title: {
		fontWeight: "600",
	},
});