import React from 'react';

import classNames from 'classnames';

import useStyles from './style.js';

const CustomCard = (props) => {
  const { name, thumbnail, onClick, onMouseOver, onMouseOut } = props;
  
  const classes = useStyles(props);
  return (
		<div
			className={classNames(classes.popupCard, "ml-0", "mr-0", "p-0")}
			onClick={onClick}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		>
			<div className="pl-0 pr-2">
				{thumbnail ? (
					<img
						className={classes.cardImg}
						src={thumbnail}
						alt="Pois thumbnail"
					/>
				) : null}
			</div>
			<div style={{ maxHeight: "65px" }} className="pl-0 pr-2">
				<h6>{name}</h6>
			</div>
		</div>
	);
};

export default CustomCard;
