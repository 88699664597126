export default {
	menuPos: {
		zIndex: 2,
		position: "fixed",
		left: "1rem",
		color: "white",
		padding: "0 1rem",
		width: 265,
	},
	autocomplete: {
		padding: ".6rem 0",
		width: "auto !important",
	},
	inputRoot: {
		padding: 0,
		color: "white",
		fontSize: "24px",
	},
	popupIndicator: {
		color: "#6699CC",
	},
	input: {
		padding: 0,
		marginLeft: ".4rem",
		minWidth: "90% !important",
		textOverflow: "unset",
		"&::placeholder": {
			color: "white",
			opacity: 1,
			fontSize: "24px",
		},
	},
	paper: {
		backgroundColor: "rgba(211, 211, 211, .95)",
		borderRadius: 0,
		margin: "0 !important",
		border: "none",
		position: "fixed !important",
		width: "210px !important",
		height: "450px",
	},
	listbox: {
		maxHeight: "80vh !important",
	},
	endAdornment: {
		top: 0,
	},
};
