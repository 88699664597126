import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'

import HomePage from "./pages/Home";
import About from "./pages/About";
import MapView from "./pages/MapView";
import LegalNote from "./pages/Informative/LegalNote";
import ConditionsOfUse from "./pages/Informative/ConditionsOfUse";

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from "@material-ui/core/styles";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import {
	QueryClient,
	QueryClientProvider,
} from 'react-query'
import PoiDetail from "./pages/PoiDetail";

const queryClient = new QueryClient()

const App = () => {
  return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<QueryClientProvider client={queryClient}>
					<ThemeProvider theme={theme}>
						<BrowserRouter>
							<Switch>
								<Route exact path="/">
									<HomePage />
								</Route>
								<Route exact path="/about">
									<About />
								</Route>
								<Route path="/map">
									<MapView />
								</Route>
								<Route path="/poi_detail/:name/:id">
									<PoiDetail />
								</Route>
								<Route path="/legal_note">
									<LegalNote />
								</Route>
								<Route path="/conditions_of_use">
									<ConditionsOfUse />
								</Route>
							</Switch>
						</BrowserRouter>
					</ThemeProvider>
				</QueryClientProvider>
			</PersistGate>
		</Provider>
	);
};

const theme = createMuiTheme({
  darkMode: false
});

export default App;
