import React, { useEffect, useState } from "react";

import {
	Map,
	TileLayer,
	Marker,
	Popup,
	Polyline,
	useLeaflet
} from "react-leaflet";

import L from "leaflet";
import dot from "../../data/icons/dot.png";

const markerIcon = L.icon({
	iconUrl: dot,
	iconSize: [(17, 17)],
  iconAnchor: [7, 11],
});

const hoveredMarkerIcon = L.icon({
	iconUrl: dot,
	iconSize: [(27, 27)],
  iconAnchor: [7, 11],
});

const NewMap = React.memo((props) => {
	const { position, zoom, onmoveend, style, className } = props;

	const [isDoingBounds, setIsDoingBounds] = useState(false);
	const [moveendEvent, setMoveendEvent] = useState(null);

	const [_timeout, _setTimeout] = useState(null);

	const moveend = (event) => {
		let { lat, lng } = event.target.getCenter();
		let _zoom = event.target.getZoom() || 1;
		// prevents moveend to be called with the same position and zoom level
		if (position[0] === lat && position[1] === lng && _zoom === zoom) {
			return;
		}

		if (_timeout) {
			clearTimeout(_timeout);
		}

		const newTimeout = setTimeout(() => {
			setMoveendEvent(event);
		}, 1000);

		_setTimeout(newTimeout);
	};

	useEffect(() => {
		if (!isDoingBounds && moveendEvent) {
			onmoveend(moveendEvent);
		}
	}, [moveendEvent, isDoingBounds, onmoveend]);

	useEffect(() => {
		if (moveendEvent) {
			setMoveendEvent(null);
			setIsDoingBounds(false);
		}
	}, [moveendEvent, setIsDoingBounds, setMoveendEvent]);

	return (
		<Map
			center={position}
			zoom={zoom || 10}
			style={style}
			className={className}
			onmoveend={moveend}
			maxBoundsViscosity={1}
		>
			<MapContent
				{...props}
				isDoingBounds={isDoingBounds}
				setIsDoingBounds={setIsDoingBounds}
			/>
		</Map>
	);
});

const MapContent = (props) => {
	const {
		pois,
		itinerary,
		zoom,
		style,
		className,
		isDoingBounds,
		setIsDoingBounds,
		onclickmarker,
		getMapSize,
		hoveredPoiId
	} = props;
	const { map } = useLeaflet();

	let width = useCurrentWitdh();

	useEffect(() => {
		if (map) {
			getMapSize(map.getSize());
		}
	}, [width, map, getMapSize]);

	useEffect(() => {
		if (map) {
			map.invalidateSize();
			getMapSize(map.getSize());
		}
	}, [map, style, className, getMapSize]);

	useEffect(() => {
		if (map && (pois.length > 0 || itinerary) && !zoom) {
			setIsDoingBounds(true);
		}
	}, [pois, zoom, itinerary, setIsDoingBounds, map]);

	useEffect(() => {
		if (isDoingBounds) {
			let bounds = pois.map((poi) => [
				poi.geometry.coordinates[1],
				poi.geometry.coordinates[0],
			]);
			if (itinerary) {
				bounds = [
					...bounds,
					...itinerary.polyline.geometry.coordinates.reduce(
						(acc, coordinate) => {
							return [...acc, coordinate.map((coord) => [coord[1], coord[0]])];
						},
						[]
					),
				];
			}
			map.fitBounds(bounds);
		}
	}, [isDoingBounds, itinerary, pois, map]);
	return (
		<>
			<TileLayer
				attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{pois.map((poi, index) => (
				<Marker
					key={
						poi.properties.Cod
							? `Cod=${poi.properties.id};${index}`
							: poi.properties.id
							? `Id=${poi.properties.id};${index}`
							: index
					}
					position={[poi.geometry.coordinates[1], poi.geometry.coordinates[0]]}
					icon={hoveredPoiId === poi.properties.id ? hoveredMarkerIcon : markerIcon}
					onmouseover={(e) => {
						e.target.openPopup();
					}}
					onmouseout={(e) => {
						e.target.closePopup();
					}}
					onclick={() => {
						onclickmarker(poi);
					}}
				>
					<Popup autoPan={false}>
						<img
							src={poi.properties.thumbnail}
							alt="thumbnail"
							style={{
								lineHeight: '15px',
								fontSize: '10px',
								width: '50px',
								height: '35px',
								paddingLeft: '5px'
							}}
						/>
						<span style={{ marginLeft: ".25rem" }}>{poi.properties.name}</span>
					</Popup>
				</Marker>
			))}
			{itinerary &&
				itinerary.polyline.geometry.coordinates.map((coordinate, index) => (
					<Polyline
						key={index}
						fillColor="#6699CC"
						dashArray="5, 5"
						dashOffset="2"
						positions={coordinate.map((coord) => [coord[1], coord[0]])}
						color="#6699CC"
					/>
				))}
		</>
	);
};

const getWidth = () =>
	window.innerWidth ||
	document.documentElement.clientWidth ||
	document.body.clientWidth;

function useCurrentWitdh() {
	let [width, setWidth] = useState(getWidth());
	useEffect(() => {
		let timeoutId = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setWidth(getWidth()), 150);
		};
		window.addEventListener("resize", resizeListener);

		return () => {
			window.removeEventListener("resize", resizeListener);
		};
	}, []);

	return width;
}

export default NewMap;
