import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  createMigrate,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'

import { appSlice } from './appSlice';
import { openDataSlice } from './openDataSlice';

import { isDev } from '../utils';

const appReducer = combineReducers({
  app: appSlice.reducer,
  openData: openDataSlice.reducer,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

const migrations = {
  0: (state) => {
    return state;
  },
};

export const persistConfig = {
  key: 'root',
  storage,
  version: 0,
  migrate: createMigrate(migrations, { debug: isDev }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    thunk: false,
  }),
});

export const persistor = persistStore(store);
