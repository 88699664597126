import React from "react";
import { Button } from "reactstrap";

import OuterLayout from "../../components/Layout/OuterLayout";
import Colophon from "../../components/Colophon";
import Info from "../../components/Info";
import Footer from "../../components/Footer";

import aboutImg from "../../data/img/about.png";
import europa from "../../data/img/europa.png";
import por from "../../data/img/por_fesr.png";
import regione from "../../data/img/regione_sardegna.png";
import sardegna_ricerche from "../../data/img/sardegna_ricerche.png";
import unica from "../../data/img/unica.png";

import useStyles from './style.js';

const About = () => {
  const firstP = "Il progetto MuseoCagliari intende affrontare e risolvere alcune criticità evidenziate dalle imprese del settore del turismo relative alla scarsa disponibilità, allo stato attuale, di banche dati ampie, ordinate e aperte, scientificamente fondate, e che consentano anche la progettazione autonoma di percorsi e itinerari turistici attraverso patrimoni culturali diversi e inconsueti, rispetto a quelli esistenti che si concentrano sul patrimonio archeologico, quello antico o quello medievale. MuseoCagliari è una piattaforma web dove è stato mappato il patrimonio di arte pubblica e architettura moderna e dove sono stati individuati, in sinergia con le imprese del Cluster che lavorano nell’ambito del turismo culturale, alcuni itinerari storico culturali dell’area metropolitana di Cagliari, che le stesse imprese propongono al pubblico. Sono più di cento gli edifici individuati, ed oltre 60 le opere d’arte pubblica e di street art.";
  const secondP =
		"Il sito racconta attraverso testi, immagini e video i singoli punti di interesse georeferenziati e rintracciabili nella mappa navigabile. Ogni punto di interesse è presentato con una scheda dove video o foto di testa e di coda immergono il visitatore nell'architettura o opera d'arte, tra i due un dettagliato testo descrittivo e una galleria di immagini forniscono informazioni sulla storia, gli autori e i dettagli delle opere.";
  const thirdP =
		"E' inoltre possibile accedere alla sezione “itinerari”, dove vengono proposti alcuni percorsi turistico-culturali che le imprese del turismo hanno collaborato a progettare. E da ogni itinerario sarà possibile raggiungere il link ai siti web delle imprese che propongono i loro tour esperienziali.";
  const fourthP =
		"Tutti i contenuti sono stati caricati come open data quindi saranno liberamente utilizzabili. Gli edifici e le opere d’arte sono stati mappati utilizzando il software open source Qgis.  La filosofia open è una parte fondamentale del progetto: i contenuti forniti sotto forma di open data favoriscono una maggiore conoscenza e valorizzazione del patrimonio culturale, e questo ne garantisce indirettamente la preservazione. Inoltre in questo modo si offrono agli utenti nuove opportunità per capire, manipolare e ricreare esperienze culturali.";

  const classes = useStyles();
  return (
		<div>
			<OuterLayout>
				<div>
					<div className={classes.logos}>
						<img src={europa} alt="" />
						<img src={regione} alt="" />
						<img src={unica} alt="" />
						<img src={por} alt="" />
						<img src={sardegna_ricerche} alt="" />
					</div>
					<img src={aboutImg} alt="About" className={classes.aboutImg} />
					<Info
						firstP={firstP}
						secondP={secondP}
						thirdP={thirdP}
						fourthP={fourthP}
					/>
					<Colophon />
					<a
						href="https://documenter.getpostman.com/view/3504383/TzCJep3i "
						target="_blank"
						rel="noopener noreferrer"
						className={classes.anchor}
					>
						<Button className={classes.btn}>
							<strong>Open data</strong>
						</Button>
					</a>
				</div>
			</OuterLayout>
			<Footer className={classes.footerAbout} />
		</div>
	);
};

export default About;
