import React from "react";
import { Row, Col } from "reactstrap";

const Info = (props) => {
	return (
		<div>
			<Row>
				<Col>
					<div>
						<h2 className="mb-4" style={{ fontWeight: "700" }}>
							{props.title}
						</h2>
						<p style={{ marginTop: "3rem" }}>{props.firstP}</p>
						<p>{props.secondP}</p>
						<p>{props.thirdP}</p>
						<p>{props.fourthP}</p>
					</div>
				</Col>
			</Row>
		</div>
	);
};

export default Info;
