import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  gradient: {
    position: "absolute",
    width: "100%",
    height: "900px",
    zIndex: 5,
    pointerEvents: "none",
    background:
      "linear-gradient(to right, rgba(23, 23, 23, 0.56) 0%, transparent 10%, transparent 90%, rgba(23, 23, 23, 0.56) 100%)",
  },
  videoContainer: (props) => ({
    overflow: "hidden",
    backgroundColor: "rgb(23, 23, 23)",
    height: props.height,
    "& iframe": {
      width: "100%",
      transform: "scale(2)",
      pointerEvents: "none",
    },
  }),
});
