import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	videoWrap: {
		zIndex: 1,
		color: "white",
		position: "absolute",
		textAlign: "center",
		top: 700,
		left: 0,
		right: 0,
		margin: "auto",
		"& h2": {
			fontWeight: 700,
			fontSize: "64px",
			textShadow: "0px 2px 10px #000000",
		},
		"& h5": {
			fontWeight: 700,
			textShadow: "0px 2px 10px #000000",
		},
	},
});
