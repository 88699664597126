import React from "react";

import useStyles from "./style.js";

const BackgroundVideo = (props) => {
  const { disabled } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.videoBackground}>
      <div className={classes.videoForeground}>
        { !disabled &&
          <iframe
            src={props.urlVideo + props.numOfSec}
            frameBorder="0"
            data-ready="true"
            title="home-video"
          ></iframe>
        }
      </div>
    </div>
  );
};

export default BackgroundVideo;
