import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { useQuery } from "../../utils/hooks";
import { useFolders, useItinerary, usePois } from "../../queries";
import { authors } from "../../data/authors";
import { formatPoiUrl } from "../../utils";

import BackgroundVideo from "../../components/BackgroundVideo";
import ItinerariesDropdown from "../../components/ItinerariesDropdown";
// import AuthorsDropdown from "../../components/AuthorsDropdown";
import AuthorSearchMenu from "../../components/AuthorSearchMenu";

import TopRightMenu from "../../components/TopRightMenu";
import NewMap from "../../components/NewMap";
import SlickCards from "../../components/Slider/SlickCards";
import Footer from "../../components/Footer";

import URI from "urijs";
import { ThemeProvider } from "@material-ui/styles";

import IconLargeMap from "@material-ui/icons/ArrowForwardIos";
import IconSmallMap from "@material-ui/icons/ArrowBackIos";

import useStyles from "./style.js";
import classNames from "classnames";
import { isDev } from "../../utils";

const setParameters = (params) => {
	var uri = new URI("");
	uri.setQuery(params);
	return uri.query();
};

const MapView = () => {
	const classes = useStyles();

	const pois = usePois();
	const folders = useFolders();

	const query = useQuery();

	const itineraryParam = query.get("itinerary");
	const itinerary = useItinerary(itineraryParam);

	const [numOfSec] = useState(Math.floor(Math.random() * 200));
	const [mapSize, setMapSize] = useState(0);
	const videoUrl =
		"https://www.youtube.com/embed/EETQQQregPY?rel=0&playlist=EETQQQregPY&autoplay=1&controls=1&fs=0&modestbranding=1&iv_load_policy=3&enablejsapi=1&widgetid=1&wmode=opaque&mute=1&loop=1";

	const [video, setVideo] = useState(videoUrl);

	const zoom = parseInt(query.get("zoom"), 10) || null;
	const coord = query.get("coord") || null;
	const position = (coord || "39.2254656,9.0932727").split(",").map(parseFloat);

	const history = useHistory();

	const open = query.get("open");
	const fromMap = query.get("from_map");
	const authorParam = query.get("author");
	const isSmall = !(open === "true");

	const [visiblePois, setVisiblePois] = useState(pois.data);
	const [hoveredPoiId, setHoveredPoiId] = useState(null);

	const [authorPois, setAuthorPois] = useState([]);
	const [authorName, setAuthorName] = useState(authorParam ? authorParam : undefined);
	const [itineraryDropdownOpen, setItineraryDropdownOpen] = useState(false);

	const getAuthorPois = (author) => {
		const authorPois = [];
		if (authors) {
			const found = authors.find((a) => {
				return a.name.toString() === author.toString();
			});
			found.tags.forEach((tag) => {
				// eslint-disable-next-line array-callback-return
				pois.data.map((poi) => {
					if (poi.properties.name.toString() === tag.toString()) {
						authorPois.push(poi);
					}
				});
			});
		}
		setAuthorPois(authorPois);
		return authorPois;
	};

	useEffect(() => {
		if (fromMap) {
			setAuthorPois([]);
		}
	}, [fromMap]);

	useEffect(() => {
		if (authorParam) {
			getAuthorPois(authorParam);
		} else {
			setAuthorPois([]);
			setAuthorName(undefined);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authorParam]);

	useEffect(() => {
		if (!authorParam) {
			if (!itineraryParam) {
				setVisiblePois(pois.data);
			} else if (itinerary.data) {
				setVisiblePois(itinerary.isFetching ? [] : itinerary.data.pois);
				itinerary.data.polyline && itinerary.data.polyline.properties.Videos
					? setVideo(
							itinerary.data.polyline &&
								itinerary.data.polyline.properties.Videos
					  )
					: setVideo(videoUrl);
			}
		} else {
			setVisiblePois(authorPois);
		}
		poisForMap();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itineraryParam, itinerary.data, authorPois, authorParam, fromMap]);

	const poisForMap = () => {
		if (authorPois.length === 0 || fromMap) {
			if ((itineraryParam && !itinerary.data) &&  itinerary.isFetching) {
				return [];
			} else if (itineraryParam && itinerary.data) {
				return itinerary.data.pois;
			} else if (!isSmall && !itineraryParam) {
				return pois.data;
			} else return [];
		}
		if (authorPois.length > 0 && !fromMap) {
			return authorPois;
		}
		if (fromMap) {
			return pois.data;
		}
	};

	return (
		<ThemeProvider theme={(outerTheme) => ({ ...outerTheme, darkMode: true })}>
			<div className={classes.gradient}></div>
			<TopRightMenu
				light
				map={itineraryParam || authorPois.length > 0}
				allPois={pois.data}
			/>
			<div className={classes.backgroundWrapper}>
				<BackgroundVideo
					disabled={isDev}
					urlVideo={video}
					numOfSec={`&start=${numOfSec}`}
				/>
			</div>
			<NewMap
				position={position}
				zoom={zoom}
				className={classNames(
					classes.map,
					isSmall && classes.isSmall,
					!isSmall && classes.isBig
				)}
				getMapSize={(mapSize) => {
					setMapSize(mapSize.x);
				}}
				pois={poisForMap()}
				hoveredPoiId={hoveredPoiId}
				itinerary={itinerary.data}
				onmoveend={(event) => {
					const bounds = event.target.getBounds();
					let _visiblePois = [];
					if (!authorParam) {
						if (!itinerary.data) {
							_visiblePois = pois.isFetching
								? []
								: pois.data.filter((poi) => {
										return bounds.contains([
											poi.geometry.coordinates[1],
											poi.geometry.coordinates[0],
										]);
								  });
						} else {
							_visiblePois = itinerary.isFetching
								? []
								: itinerary.data.pois.filter((poi) => {
										return bounds.contains([
											poi.geometry.coordinates[1],
											poi.geometry.coordinates[0],
										]);
								  });
						}
					} else {
						_visiblePois = authorPois.filter((poi) => {
							return bounds.contains([
								poi.geometry.coordinates[1],
								poi.geometry.coordinates[0],
							]);
						});
					}
					setVisiblePois(_visiblePois);
					let { lat, lng } = event.target.getCenter();
					let _zoom = event.target.getZoom() || 1; // when trying to zoom back too fast defaults to 1
					history.push({
						pathname: "/map",
						search: setParameters({
							coord: `${lat > 0 ? lat : 0},${lng > 0 ? lng : 0}`,
							zoom: _zoom,
							author: authorName || undefined,
							itinerary: itineraryParam || undefined,
							open,
						}),
					});
				}}
				onclickmarker={(poi) => {
					history.push(
						`/poi_detail/${formatPoiUrl(poi.properties.name)}/${
							poi.properties.id
						}`
					);
				}}
			/>
			<div className={classes.itineraryDropdown}>
				<ItinerariesDropdown
					folders={folders.data}
					itineraryDropdownOpen={(itineraryDropdownOpen) => {
						setItineraryDropdownOpen(itineraryDropdownOpen);
					}}
					handleChange={(itineraryName) => {
						setAuthorPois([]);
						setAuthorName(undefined);
						history.push({
							pathname: "/map",
							search: setParameters({
								itinerary: itineraryName,
								author: undefined,
								open: "true",
							}),
						});
					}}
				/>
			</div>
			{/* {!itineraryDropdownOpen && (
				<div className={classes.authorsDropdown}>
					<AuthorsDropdown
						authors={authors}
						handleAuthorChange={(author) => {
							setAuthorName(author.name);
							history.push({
								pathname: "/map",
								search: setParameters({
									author: author.name,
									itinerary: undefined,
									open: "true",
								}),
							});
							getAuthorPois(author.name)
						}}
					/>
				</div>
			)} */}
			{!itineraryDropdownOpen && (
				<div className={classes.authorsDropdown}>
					<AuthorSearchMenu
						authors={authors}
						authorParam={authorParam}
						fromMap={fromMap}
						handleAuthorChange={(author) => {
							if (author) {
								setAuthorName(author.name);
								history.push({
									pathname: "/map",
									search: setParameters({
										author: author.name,
										itinerary: undefined,
										open: "true",
									}),
								});
								getAuthorPois(author.name);
							}
						}}
					/>
				</div>
			)}
			{isSmall && (
				<div className={classes.iconLargeMap}>
					<IconLargeMap
						style={{ fontSize: 40, cursor: "pointer" }}
						onClick={() => {
							history.push({
								pathname: "/map",
								search: setParameters({
									open: true,
									itinerary: itineraryParam ? itineraryParam : undefined,
								}),
							});
						}}
					/>
				</div>
			)}
			{!isSmall && (
				<div className={classes.iconSmallMap}>
					<IconSmallMap
						style={{ fontSize: 40, cursor: "pointer" }}
						onClick={() => {
							history.push({
								pathname: "/map",
								search: setParameters({
									open: false,
									itinerary: itineraryParam ? itineraryParam : undefined,
								}),
							});
						}}
					/>
				</div>
			)}
			{!isSmall && (
				<SlickCards
					allPois={visiblePois}
					mapSize={mapSize}
					onHoveringCard={(id) => {
						setHoveredPoiId(id);
					}}
				/>
			)}
			<Footer className={classes.footerAbout} />
		</ThemeProvider>
	);
};

export default MapView;
