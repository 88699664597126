export default ({
  menuPos: {
  zIndex: 2,
  position: 'fixed',
  right: '1.4rem',
  backgroundColor: 'rgba(211, 211, 211, .95)',
  color: 'white',
  padding: '0 1rem',
  width: '280px'
  },
  autocomplete: {
    padding: '.6rem 0',
    width: 'auto !important'
  },
  inputRoot: {
    padding: 0
  },
  input: {
    padding: 0,
    "&::placeholder": {
      color: "#6699CC",
      opacity: 1
    }
  },
  underline: {
    padding: '.4rem',
    "&:after": {
      borderWidth: ".1rem",
      borderColor: "black !important"
    }
  },
  popper: {
    position: 'fixed !important',
    right: '1.4rem !important'
  },
  paper: {
    backgroundColor: "rgba(211, 211, 211, .95)",
    borderRadius: 0,
    margin: 0,
    marginLeft: '-1rem',
    border: "none",
    position: 'fixed !important',
    width: '280px !important',
    height: '500px'
  },
  listbox: {
    maxHeight: '80vh !important'
  },
  endAdornment: {
    display: 'none'
  }
});
