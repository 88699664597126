import React, { useState } from "react";
import { Alert, Button } from "reactstrap";

import classNames from 'classnames';

import useStyles from './style.js';

const AlertCookie = ({ onClick }) => {
  const [visible, setVisible] = useState(true);
  const onDismiss = () => setVisible(false);

  const classes = useStyles();
  return (
    <div id="cookie">
      <Alert
        className={classes.alert}
        isOpen={visible}
        toggle={onDismiss}
      >
        <div className="clearfix">
          <strong>Questo sito web utilizza i cookie</strong>
          <p className="mt-1 mb-1">
            Il nostro sito salva piccoli pezzi di informazioni (cookie) sul dispositivo, esclusivamente per fini tecnici.
            È possibile disattivare l'utilizzo di cookies modificando le impostazioni del tuo browser.
          </p>
          <p className="mb-1">
            Continuando la navigazione si acconsente all'utilizzo dei cookie.
          </p>
          <Button
            className={
              classNames([
                'btn',
                'float-right',
                classes.btnCookie
              ])
            }
            onClick={onClick}
          >
            Accettare
          </Button>
        </div>
      </Alert>
    </div>
  );
};

export default AlertCookie;
