export const authors = [
	{
		name: "Dino De Gioannis",
		tags: ["Scuola 'Alberto Riva Villasanta'"],
	},
	{
		name: "Bartolomeo Ravenna",
		tags: ["Scuola 'Alberto Riva Villasanta'"],
	},
	{
		name: "Lorenzo Leone",
		tags: ["Scuola 'Alberto Riva Villasanta'"],
	},
	{
		name: "Nicolò Mura",
		tags: ["Ex Museo Archeologico"],
	},
	{
		name: "Riccardo Simonetti",
		tags: [
			"Palazzina della Società di Mutuo Soccorso degli Operai",
			"Palazzo Valdès",
			"Semoleria Italiana",
			"Camera di Commercio",
			"Basilica di N.S. di Bonaria",
		],
	},
	{
		name: "Enrico Mandolesi",
		tags: [
			"Istituto di Genetica e Istituto di Zoologia",
			"Complesso INA-Casa La Palma",
		],
	},
	{
		name: "Vincenzo Marchi",
		tags: ["La città del sale"],
	},
	{
		name: "Carlo Bagnasco",
		tags: ["Semoleria Italiana"],
	},
	{
		name: "Alberto Sanjust",
		tags: ["Silos del Consorzio Agrario"],
	},
	{
		name: "Luca Beltrami",
		tags: ["Camera di Commercio"],
	},
	{
		name: "Giovanni Battista Casati",
		tags: ["Camera di Commercio"],
	},
	{
		name: "Giacomo Crespi",
		tags: ["Banco di Napoli"],
	},
	{
		name: "Riccardo Vanzetti",
		tags: ["Banco di Napoli"],
	},
	{
		name: "Augusto Valente",
		tags: ["Provveditorato alle Opere Pubbliche della Sardegna", "Case INCIS"],
	},
	{
		name: "Ufficio del Genio Civile di Cagliari",
		tags: ["Archivio di Stato e Genio civile", "Clinica Pediatrica"],
	},
	{
		name: "Angelo Binaghi",
		tags: ["Palazzo delle Scienze", "Clinica Pediatrica", "Casa del Goliardo", "Legione dei Carabinieri"],
	},
	{
		name: "Flavio Scano",
		tags: ["Palazzo delle Scienze", "Palazzo Scano", "Palazzo Tirso"],
	},
	{
		name: "Vittorio Tronci Peluffo",
		tags: ["Palazzo delle Poste"],
	},
	{
		name: "Paolo Bo",
		tags: ["Palazzo delle Poste"],
	},
	{
		name: "Domenico Dettori",
		tags: ["Palazzo di Giustizia"],
	},
	{
		name: "Ubaldo Badas",
		tags: ["Palazzo di Giustizia", "Scuola all'aperto 'Attilio Mereu'", "Albergo del Povero", "Ingresso ai Giardini pubblici", "Sacrario ai Caduti al Parco delle Rimembranze", "Case popolarissime", "Colonia Dux", "Palazzo del Banco di Roma", "Villa in via Milano", "Complesso residenziale in via Toscana", "Palazzo Usai", "Palazzo Costamarras", "Fiera Campionaria - Padiglione dell'Agricoltura", "Fiera Campionaria - Ingresso", "Palazzo della Regione Autonoma della Sardegna", "Passeggiata del Terrapieno", "Galleria Comunale d'Arte", "Fiera Campionaria - Padiglione Mobili e Arredamento"],
	},
	{
		name: "Emanuele Filiberto Paolini",
		tags: ["Casa del Balilla", "Ingresso Stadio Amsicora"],
	},
	{
		name: "Giorgio Gandini",
		tags: ["Aeroporto militare"],
	},
	{
		name: "Umberto Traverso",
		tags: ["Aeroporto militare"],
	},
	{
		name: "Salvatore Rattu",
		tags: ["Palazzo Zedda-Zedda", "Palazzo Ebau", " Casa Pintus"],
	},
	{
		name: "Francesco Giarrizzo",
		tags: ["Palazzo Gaudina", "Facciata della Cattedrale"],
	},
	{
		name: "Adolfo Doccetti",
		tags: ["Palazzo Massoni"],
	},
	{
		name: "Adalberto Libera",
		tags: ["Complesso IEEP", "Padiglione della Cassa per il Mezzogiorno"],
	},
	{
		name: "Cesare Valle",
		tags: ["Cimitero di San Michele"],
	},
	{
		name: "Cesare Valle",
		tags: ["Cimitero di San Michele"],
	},
	{
		name: "Enrico Bianchini",
		tags: ["Chiesa e Chiostro di S. Domenico"],
	},
	{
		name: "Raffaello Fagnoni",
		tags: ["Chiesa e Chiostro di S. Domenico"],
	},
	{
		name: "Adriano Cambellotti",
		tags: ["Scalinata di N. S. di Bonaria", "Chiesa di S. Lucia"],
	},
	{
		name: "Lucio Cambellotti",
		tags: ["Scalinata di N. S. di Bonaria", "Chiesa di S. Lucia"],
	},
	{
		name: "Piero Gazzola",
		tags: ["Cittadella dei Musei"],
	},
	{
		name: "Libero Cecchini",
		tags: ["Cittadella dei Musei", "EXMA - Centro Culturale"],
	},
	{
		name: "Renzo Piano",
		tags: ["Ex Banca CIS"],
	},
	{
		name: "ArAssociati",
		tags: ["Parco Telematico Tiscali", "Residenza privata"],
	},
	{
		name: "Gigi Ghò",
		tags: ["Palazzo Enel"],
	},
	{
		name: "Giuseppe Angilella",
		tags: ["Palazzo Enel"],
	},
	{
		name: "Pietro Pedemonte",
		tags: ["Palazzo del Banco di Roma"],
	},
	{
		name: "Pier Francesco Cherchi",
		tags: ["Condominio M"],
	},
	{
		name: "Mario Cubeddu",
		tags: ["Condominio M"],
	},
	{
		name: "Mario Cubeddu",
		tags: ["Edificio residenziale", "Palazzo della Banca di Sassari"],
	},
	{
		name: "Giuliana Genta",
		tags: ["Chiesa di Cristo Re"],
	},
	{
		name: "Silvano Panzarasa",
		tags: ["Chiesa di Cristo Re"],
	},
	{
		name: "Marco Piloni",
		tags: ["Chiesa dei SS. MM. Giorgio e Caterina"],
	},
	{
		name: "Francesco Giachetti",
		tags: ["Chiesa dei SS. MM. Giorgio e Caterina"],
	},
	{
		name: "Antonello Tramontin",
		tags: ["Tribunale e Procura Minorile"],
	},
	{
		name: "Andrea de Eccher",
		tags: ["Lazzaretto S. Elia"],
	},
	{
		name: "Giuseppe Vallifuoco",
		tags: ["Edificio industriale e per uffici"],
	},
	{
		name: "Ilene Steingut",
		tags: ["Edificio industriale e per uffici"],
	},
	{
		name: "Studio Progettisti Associati",
		tags: ["Istituto Tecnico Agrario Duca degli Abruzzi"],
	},
	{
		name: "Delogu Lixi Architetti Associati",
		tags: ["Piazza S. Barbara"],
	},
	{
		name: "Salvatore Peluso",
		tags: ["Museo Arca del Tempo"],
	},
	{
		name: "2+1 Officina Architettura",
		tags: ["Housing sociale"],
	},
	{
		name: "Giovanni Maciocco",
		tags: ["Aeroporto Aerostazione Cagliari-Elmas"],

	},
	{
		name: "Lucio Ortu",
		tags: ["Museo delle Ferrovie della Sardegna"],
	},
	{
		name: "Enrico Pisano",
		tags: ["Palazzo della Regione Autonoma della Sardegna"],
	},
	{
		name: "Luciano Galmozzi",
		tags: ["Teatro Lirico"],
	},
	{
		name: "Pierfrancesco Ginoulhiac",
		tags: ["Teatro Lirico"],
	},
	{
		name: "Teresa Ginouliach Arslan",
		tags: ["Teatro Lirico"],
	},
	{
		name: "Giacinto Magrelli",
		tags: ["Chiesa della Beata Vergine della Salute"],
	},
	{
		name: "Paolo Rossi de Paoli",
		tags: ["Quartiere CEP"],
	},
	{
		name: "Maria Freddi",
		tags: ["Quartiere CEP"],
	},
	{
		name: "Carlo Aymerich",
		tags: ["Quartiere CEP"],
	},
	{
		name: "Vinicio Demontis",
		tags: ["Quartiere CEP"],
	},
	{
		name: "Amos Mainardi",
		tags: ["Quartiere CEP"],
	},
	{
		name: "Amos Mainardi",
		tags: ["Quartiere CEP"],
	},
	{
		name: "Mauro Cabras",
		tags: ["Complesso INA-Casa La Palma"],
	},
	{
		name: "Domenico Mezzini",
		tags: ["Fiera Campionaria - Padiglione dell'Agricoltura", "Fiera Campionaria - Padiglione Mobili e Arredamento"],
	},
	{
		name: "Albino Manca",
		tags: ["Legione dei Carabinieri"],
	},
	{
		name: "Duilio Cambellotti",
		tags: ["Chiesa di S. Lucia"],
	},
	{
		name: "Franco D’Urso",
		tags: ["Chiesa di S. Lucia"],
	},
	{
		name: "Pinuccio Sciola ",
		tags: ["Parco Telematico Tiscali"],
	},
	{
		name: "Olafur Eliasson",
		tags: ["Parco Telematico Tiscali"],
	},
	{
		name: "Maria Lai",
		tags: ["Parco Telematico Tiscali"],
	},
	{
		name: "Michelangelo Pistoletto",
		tags: ["Parco Telematico Tiscali"],
	},
	{
		name: "Alberto Garutti",
		tags: ["Parco Telematico Tiscali"],
	},
	{
		name: "Anna Saba",
		tags: ["Cittadella dei Musei", "EXMA - Centro Culturale"],
	},
	{
		name: "Anna Saba",
		tags: ["Cittadella dei Musei", "EXMA - Centro Culturale"],
	},
	{
		name: "Antonio Paradiso",
		tags: ["Fiore"],
	},
	{
		name: "Assen Peikov",
		tags: ["Le attività produttive della Sardegna"],
	},
	{
		name: "Bruno Meloni",
		tags: ["Cavallo di Troia", "Modulor", "Il labirinto di Dedalo"],
	},
	{
		name: "Costantino Nivola",
		tags: ["Parco Telematico Tiscali", "Sculture del Palazzo del Consiglio Regionale della Sardegna"],
	},
	{
		name: "Emilia Palomba",
		tags: ["Fiera Campionaria - Padiglione dell'Agricoltura"],
	},
	{
		name: "Eugenio Tavolara",
		tags: ["Palazzo Enel", "Palazzo del Banco di Roma", "Stele votiva Madonna dell'ETFAS"],
	},
	{
		name: "Giuseppe Silecchia",
		tags: ["Fiera Campionaria - Padiglione dell'Agricoltura", "Palazzo Costamarras"],
	},
	{
		name: "Mauro Staccioli",
		tags: ["Scultura-intervento", "Vele,Barche,Fregio su cemento"],
	},
	{
		name: "Domenico Mimmo Paladino",
		tags: ["I dormienti"],
	},
	{
		name: "Rosanna Rossi",
		tags: ["Templi dell'acqua", "Protezioni laterali delle passerelle pedonali dello Stadio Sant'Elia", "Piazza d'Arte – Meridiana d'artista", "Prospetto Cieco", "Grande Cerchio"],
	},
	{
		name: "Felice Casorati",
		tags: ["Chiesa e Chiostro di S. Domenico"],
	},
	{
		name: "Francesco Menzio",
		tags: ["Chiesa e Chiostro di S. Domenico"],
	},
	{
		name: "Angelo Liberati",
		tags: ["Prospetto cieco"],
	},
	{
		name: "Gaetano Brundu",
		tags: ["Prospetto cieco"],
	},
	{
		name: "Tonino Casula",
		tags: ["Prospetto cieco"],
	},
	{
		name: "Primo Pantoli",
		tags: ["Prospetto cieco"],
	},
	{
		name: "Ermanno Leinardi",
		tags: ["Prospetto cieco"],
	},
	{
		name: "Ciredz",
		tags: ["Ciredz+Tellas ST", "Via Schiavazzi", "Panda Monserrato"],
	},
	{
		name: "Tellas",
		tags: ["Ciredz+Tellas ST", "Via Schiavazzi", "Under the city"],
	},
	{
		name: "Crisa",
		tags: ["Crisa ST", "Wind line", "Be-sogni", "Via Schiavazzi", "Panda Monserrato"],
	},
	{
		name: "Manu Invisible",
		tags: ["Michelangelo", "Foscolo", "Mauelleddu", "Anziano e Bambino", "Manu Invisible ST", "Centro sociale EXME", "Terrapieno"],
	},
	{
		name: "Daniele Gregorini",
		tags: ["Galleria del sale"],
	},
	{
		name: "Ericailcane",
		tags: ["Via Schiavazzi"],
	},
	{
		name: "La Fille Bertha",
		tags: ["Galleria del sale", "Terrapieno", "Via San Saturnino"],
	},
];