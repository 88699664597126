import React from 'react';

import { Link } from "react-router-dom";

import classNames from 'classnames';

import useStyles from './style.js';

const Footer = (props) => {
  const { className } = props;
  const classes = useStyles(props);
  return (
		<footer className={classNames(classes.footer, className)}>
			<a
				href="mailto:museocagliari@unica.it"
				className={classNames(classes.footerLink, classes.contactLink)}
			>
				Contattaci
			</a>
			{/* <Link to="/legal_note" className={classes.footerLink}>
        Note legali
      </Link>
      */}
			<Link to="conditions_of_use" className={classes.footerLink}>
				Condizioni d'uso
			</Link>
		</footer>
	);
};

export default Footer;
