import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	logos: {
		display: "inline-flex",
		justifyContent: "center",
    width: '100%',
    marginTop: '1.75rem',
    marginLeft: '1.25rem',
		"& img": {
			// width: 130,
			height: 60,
      marginRight: '3rem'
		},
	},
	aboutImg: {
		width: "100%",
		marginTop: "2rem",
	},
	btn: {
		width: "100%",
		marginTop: "3rem",
		marginBottom: "2rem",
		borderRadius: 0,
		backgroundColor: "black",
		textTransform: "uppercase",
		padding: "1rem",
		fontSize: "36px",
		"&:hover": {
			backgroundColor: "black !important",
		},
	},
	anchor: {
		color: "white",
		"&:hover": {
			color: "white",
			textDecoration: "none",
		},
	},
	footerAbout: {
		position: "initial",
	},
});
