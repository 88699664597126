import aws from "aws-sdk";

const fetchArchitetturePois = async () => {
  aws.config.update({
    accessKeyId: "AKIATSG6XKATLTOSQ7QM",
    secretAccessKey: "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",
    region: "eu-west-1",
  });
  const s3 = new aws.S3({ apiVersion: "2006-03-01" });
  const bucketParams = {
    Bucket: "stag.data.museocagliari.it",
    Prefix: "architetture/",
  };
  const architettureFile = await (
    await s3.listObjectsV2(bucketParams).promise()
  ).Contents.map((res) => {
    return res.Key;
  })
    .filter((res) => res.includes(".js"))
    .map((res) => {
      return res;
    });

  let archiPois = architettureFile.map(async (architettureFile) => {
    const response = await (
      await fetch(
        `https://s3-eu-west-1.amazonaws.com/stag.data.museocagliari.it/${architettureFile}`
      )
    ).text();
    return JSON.parse(response.slice(response.indexOf("{")));
  });

  archiPois = await Promise.all(archiPois);

  const architetture = archiPois[0];
  return architetture;
};

const fetchArtePois = async () => {
  aws.config.update({
    accessKeyId: "AKIATSG6XKATLTOSQ7QM",
    secretAccessKey: "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",
    region: "eu-west-1",
  });
  const s3 = new aws.S3({ apiVersion: "2006-03-01" });
  const bucketParams = {
    Bucket: "stag.data.museocagliari.it",
    Prefix: "arte/",
  };
  const arteFile = await (
    await s3.listObjectsV2(bucketParams).promise()
  ).Contents.map((res) => {
    return res.Key;
  })
    .filter((res) => res.includes(".js"))
    .map((res) => {
      return res;
    });
  let artePois = arteFile.map(async (arteFile) => {
    const response = await (
      await fetch(
        `https://s3-eu-west-1.amazonaws.com/stag.data.museocagliari.it/${arteFile}`
      )
    ).text();
    return JSON.parse(response.slice(response.indexOf("{")));
  });

  artePois = await Promise.all(artePois);

  let arte = artePois[0];
  return arte;
};

async function fetchFolders() {
  aws.config.update({
    accessKeyId: "AKIATSG6XKATLTOSQ7QM",
    secretAccessKey: "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",
    region: "eu-west-1",
  });
  // Create S3 service object
  const s3 = new aws.S3({ apiVersion: "2006-03-01" });

  var bucketParams = {
    Bucket: "stag.data.museocagliari.it",
    Prefix: "itinerari/",
  };
  // Call S3 to list the buckets
  const folders = await (
    await s3.listObjects(bucketParams).promise()
  ).Contents.map((res) => {
    return res.Key.replace("itinerari/", "").replace("/", "");
  })
    .filter((res) => !res.includes(".js"))
    .slice(1);

  return folders;
}

const fetchItinerary = async (folder) => {
  aws.config.update({
    accessKeyId: "AKIATSG6XKATLTOSQ7QM",
    secretAccessKey: "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",
    region: "eu-west-1",
  });
  const s3 = new aws.S3({ apiVersion: "2006-03-01" });
  const bucketParams = {
    Bucket: "stag.data.museocagliari.it",
    Prefix: `itinerari/${folder}`,
  };

  const fileNames = await (
    await s3.listObjectsV2(bucketParams).promise()
  ).Contents.map((res) => {
    return res.Key;
  })
    .filter((res) => res.includes(".js"))
    .map((res) => {
      return res;
    });

  let allFiles = fileNames.map(async (fileName) => {
    const response = await (
      await fetch(
        `https://s3-eu-west-1.amazonaws.com/stag.data.museocagliari.it/${fileName}`
      )
    ).text();
    return JSON.parse(response.slice(response.indexOf("{")));
  });

  allFiles = await Promise.all(allFiles);

  let itinerary = {
    name: folder,
    pois: [],
    polyline: null,
  };

  allFiles.forEach((file) => {
    file.features.forEach((feat) => {
      if (feat.geometry && feat.geometry.type === "Point") {
        itinerary.pois.push(feat);
      }
      if (feat.geometry && feat.geometry.type === "MultiLineString") {
        itinerary.polyline = feat;
      }
    });
  });

  return itinerary;
};

export const restructurePoi = (poi) => {
  if (poi) {
    const poiRestructured = {
      type: poi.type,
      properties: {
        id: poi.properties.Cod || poi.properties.id,
        name: poi.properties.Denominazi || poi.properties.Titolo,
        x: poi.properties.x || null,
        y: poi.properties.y || null,
        year: poi.properties.Anno,
        address: poi.properties.Indirizzo || poi.properties.Indirizzo,
        city: poi.properties["CittÃ "],
        province: poi.properties.Provincia,
        designer: poi.properties.Progettist || poi.properties.Artista,
        historic_time: poi.properties["Periodo st"],
        made: poi.properties.Realizzazi,
        remade: poi.properties.Realizzazi_1,
        restoration: poi.properties.Restauro,
        notes: poi.properties.Note,
        ref: poi.properties.Riferiment,
        docs: poi.properties.Documenti,
        iconography: poi.properties.Iconografi,
        object_of: poi.properties["Oggetto di"],
        cat: poi.properties.Categoria,
        type: poi.properties.Tipologia,
        image: poi.properties.Immagine,
        images: poi.properties.Immagini,
        biblio: poi.properties.Biblio,
        nearest: poi.properties.nearest,
        thumbnail: poi.properties.Thumbnail,
        videos: poi.properties.Videos || poi.properties.videos,
        itineraries: poi.properties.Itinerari || null,
        description:
          poi.properties.Descrizion || poi.properties["Profilo Ar"],
        description2:
          poi.properties.description2,
        caption: poi.properties.Didascalia || null,
        image_header: poi.properties.image_header,
        image_footer: poi.properties.image_footer,
        video_header: poi.properties.video_header,
        video_footer: poi.properties.video_footer,
      },
      geometry: {
        type: poi.geometry ? poi.geometry.type : null,
        coordinates: poi.geometry ? poi.geometry.coordinates : null,
      },
    };
    return poiRestructured;
  }
};

export {
  fetchArchitetturePois,
  fetchArtePois,
  fetchFolders,
  fetchItinerary,
};
