import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
	adverts: {
		right: ".5rem",
		top: "13rem",
		zIndex: 6,
		position: "fixed",
	},
	listItems: {
		paddingLeft: 0,
		listStyleType: "none",
	},
	advertLogo: {
		width: "2.5rem",
		height: "2.5rem",
		marginBottom: ".5rem",
		boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.5)",
		backgroundColor: "#ffffff",
	},
});
