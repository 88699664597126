import React from "react";

import Video from "../../components/Video/index";
import Image from "../../components/Image/index";
import ContentPoi from "../../components/ContentPoi/index";
import TopRightMenu from "../../components/TopRightMenu";

import { formatPoiUrl } from "../../utils";

import { useParams } from "react-router";
import { usePois } from "../../queries";

import "./style.css";
import useStyles from "./style.js";

const PoiDetail = () => {
  const { id, name } = useParams();
	
  const pois = usePois();
	const poi = pois.data.find(p => {
		return (
			p.properties.id.toString() === id.toString() &&
			formatPoiUrl(p.properties.name) === name
		);
	});

  const swapVidToImgHeader = () => {
    const vid = poi.properties.video_header;
    const img = poi.properties.image_header;
    if (vid) {
      return <Video height="900px" video={vid} />;
    } else if (img) {
      return <Image height="900px" image={img} />;
    }
    return <Image height="900px" />;
  };

  const swapVidToImgFooter = () => {
    const vid = poi.properties.video_footer;
    const img = poi.properties.image_footer;
    if (vid) {
      return <Video height="900px" video={vid} />;
    } else if (img) {
      return <Image height="900px" image={img} />;
    }
    return <Image height="900px" />;
  };

  const classes = useStyles();

  if (!poi) {
    return <div className="text-center mt-5">
			Non è possibile caricare questa pagina. Riprova.
		</div>;
  }
  return (
		<div className="marker-detail-container">
			<div className="stack-top">
				<TopRightMenu light allPois={pois.data} />
			</div>
			<div className={classes.videoWrap}>
				<h2 className="px-5">
					{poi.properties.name
						? poi.properties.name
						: poi.properties.name && ""}
				</h2>
				<h5>{poi.properties.designer ? poi.properties.designer : ""}</h5>
			</div>
			{swapVidToImgHeader()}
			<ContentPoi
				itinerari={poi.properties.itineraries}
				title={poi.properties.name}
				caption={poi.properties.caption}
				description={poi.properties.description}
				biblio={poi.properties.biblio}
				iconography={poi.properties.iconography}
				description2={poi.properties.description2}
				images={poi.properties.image}
			/>
			{swapVidToImgFooter()}
		</div>
	);
};

export default PoiDetail;
