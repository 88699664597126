import { useQuery } from 'react-query'
import { store } from "../store";
import { openDataSlice } from "../store/openDataSlice";
import { fetchArchitetturePois, fetchArtePois, fetchFolders, fetchItinerary, restructurePoi } from "../utils/mapFunctions";

const fetchPois = async () => {
  let responses = await Promise.all([fetchArchitetturePois(), fetchArtePois()]);
  responses = responses.map(response => {
    return {
      ...response,
      features: response.features.map(restructurePoi)
    };
  })
  return responses.reduce((prev, response) => [...prev, ...response.features], [])
}

export const usePois = () => {
  return useQuery('pois', () => fetchPois(),
    {
      cacheTime: 1000 * 60 * 24,
      initialData: () => {
        const state = store.getState();
        return state.openData.pois;
      },
      onSuccess: (data) => {
        store.dispatch(openDataSlice.actions.setPois(data));
      }
    }
  );
}

export const useFolders = () => {
  return useQuery('folders', () => fetchFolders(),
    {
      cacheTime: 1000 * 60 * 24,
      initialData: () => {
        const state = store.getState();
        return state.openData.folders;
      },
      onSuccess: (data) => {
        store.dispatch(openDataSlice.actions.setFolders(data));
      }
    }
  );
}

const fetchItineraryPois = async (folder) => {
  let response = await fetchItinerary(folder);
  return {
    ...response,
    pois: response.pois.map(restructurePoi)
  }
}

export const useItinerary = (folder) => {
  return useQuery(['itinerary', folder], () => fetchItineraryPois(folder),
    {
      enabled: !!folder,
      cacheTime: 1000 * 60 * 24,
      initialData: () => null,
    },
  );
}
